<!-- Modal that serves as a landing point for feature manipulation. There are tabs that are imported into this modal where the user searches for promotions/non promotion items, adds them to the feature
modifies the feature, etc. -->
<template>
  <v-dialog :value="value" persistent :width="isExistingFeature ? '1800' : '850'">
    <v-card class="fill">
      <v-toolbar flat class="fill">
      <v-breadcrumbs :items="breadcrumbs">
          <template #item="{ item }">
            <v-breadcrumbs-item  :style="!item.disabled ? 'cursor:pointer' : ''" :disabled="item.disabled" @click='$emit("update:value",false)'>
              <span :class="!item.disabled ? 'primary--text' : ''">{{item.text}}</span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-spacer/>
         <v-menu v-if="isExistingFeature" offset-y offset-x left bottom :nudge-right="5" :nudge-top="5">
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn :disabled="readonly" v-bind="attrs" v-on="{ ...tooltip, ...menu }" icon
                  color="delete" class="white--text">
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
          <v-card class="pa-6">
            <v-row justify="center" class="py-4">
              <span>Delete Feature?</span>
            </v-row>
            <v-row>
              <v-btn text :class="body">Cancel</v-btn>
              <v-btn :class="body" class="primary white--text ml-2" @click="deleteAdFeature">
                Confirm
              </v-btn>
            </v-row>
          </v-card>
        </v-menu>
          <v-btn icon  @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        </v-toolbar>
      <v-card-title :class="title" class="py-0">
        {{isExistingFeature ? 'Edit Feature' : 'Create Feature'}}
        <v-tooltip v-if="isExistingFeature" top>
          <template #activator="{ on }">
            <v-btn v-on="on" class="ml-3" icon :class="body" @click="showInfo = !showInfo">
              <v-icon>{{showInfo ? 'mdi-eye-off' : 'mdi-eye'}}</v-icon>
              <!-- {{showInfo ? 'Hide Info' : 'Show Info'}} -->
            </v-btn>
          </template>
          <span>{{showInfo ? 'Collapse Info' : 'Expand Info'}}</span>
        </v-tooltip>
        <v-spacer/>
        <small v-if="!showInfo">
                    <strong>Page:</strong> {{feature.page.name}}
                    <v-icon color="#000">mdi-circle-small</v-icon>
                    <strong>Position:</strong> {{feature.position.name}}
                    <v-icon color="#000">mdi-circle-small</v-icon>
                    <strong>Count:</strong> {{feature.count}}
                    <v-icon color="#000">mdi-circle-small</v-icon>
                    <strong>Ad Retail:</strong> ${{formatCurrencyDisplay(feature.ad_retail)}}
                  </small>
      </v-card-title>
      <v-form v-if="showInfo" ref="form" class="px-6 fill" flat>
        <v-row dense class="py-4">
          <v-col :cols="featPage">
            <v-autocomplete
              :readonly="readonly"
              :rules="[v => !!v.id || 'Page Required']"
              :class="body"
              auto-select-first
              background-color="#fff"
              dense
              outlined
              placeholder="Select Page"
              label="Page"
              :items="pages"
              item-text="name"
              item-value="id"
              return-object
              v-model="localPage"
              autocomplete="off"
              hide-details="auto"
              :menu-props="{ bottom: true, offsetY: true, maxHeight: 220, rounded: '' }"
              @change='getPagePositions'>
              <template v-slot:item="data">
                  <template>
                    <v-list-item-content> 
                      <v-list-item-title>{{data.item.name}}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
            </v-autocomplete>
          </v-col>
          <v-col :cols="featPos">
            <v-autocomplete
              :readonly="readonly"
              :rules="[v => !!v.id || 'Feature Position Required']"
              :class="body"
              auto-select-first
              background-color="#fff"
              dense
              outlined
              placeholder="Select Feature Postition" label="Feature Position"
              :items.sync="pagePositions"
              item-text="name"
              item-value="id"
              return-object
              v-model="feature.position"
              autocomplete="off"
              hide-details="auto"
              :menu-props="{ bottom: true, offsetY: true, maxHeight: 220, rounded: '' }">
              <template v-slot:item="data">
                  <template>
                    <v-list-item-content> 
                      <v-list-item-title>{{data.item.name}}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
            </v-autocomplete>
          </v-col>
          <v-col :cols="featText">
            <v-text-field
              :readonly="readonly"
              :class="body"
              dense
              background-color="#fff"
              outlined
              v-model="feature.feature_text"
              label="Feature Text"
              placeholder="Add Feature Text"
              autocomplete="off"
              :rules="!readonly ? featureTextRules : []"
              clearable
              hide-details="auto">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense align="center" class="pb-5">
          <v-col v-if='isExistingFeature' :cols="isExistingFeature ? 2 : 2">
            <v-text-field
              :readonly="readonly"
              :rules="!readonly ? [v => !!v || 'Count Required', validate_count] : []"
              :class="body"
              outlined
              dense
              type="number"
              background-color="input"
              label="Count"
              v-model="feature.count"
              prepend-inner-icon="mdi-pound"
              hide-details="auto">
            </v-text-field>
          </v-col>
          <v-icon v-if='isExistingFeature' dense >mdi-at</v-icon>
          <v-col v-if='isExistingFeature' :cols="isExistingFeature ? 2 : 2">
            <v-text-field
              :readonly="readonly"
              :rules="!readonly ? [v => !!v || 'Ad Retail Required', validate_ad_retail] : []"
              :class="body"
              outlined
              dense
              type="number"
              min="0.01"
              background-color="input"
              label="Ad Retail" 
              v-model="feature.ad_retail"
              prepend-inner-icon="mdi-currency-usd"
              hide-details="auto"
              >
            </v-text-field>
          </v-col>
          <v-col v-if="$auth.tenant !== 'pricechopper' && $auth.tenant !== 'alliance-retail-group'" :cols="isExistingFeature ? 2 : 3">
            <v-text-field
              :readonly="readonly"
              :class="body"
              outlined
              dense
              background-color="input"
              label="Sign Detail"
              v-model="feature.sign_detail"
              :rules="[validateText]"
              clearable
              hide-details="auto">
            </v-text-field>
          </v-col>
          <!-- <v-col :cols="isExistingFeature ? 2 : 4">
            <v-textarea :readonly="readonly" :class="body" outlined dense background-color="input" label="Family" v-model="feature.family" rows="1" no-resize clearable />
          </v-col> -->
          <v-col :cols="featNotes">
            <v-text-field
              :readonly="readonly"
              :class="body"
              outlined
              dense
              background-color="input"
              label="Feature Notes (Optional)"
              v-model="feature.notes"
              :rules="featureNotesRules"
              clearable
              hide-details="auto">
            </v-text-field>
          </v-col>
          <v-col align="right">
            <v-btn v-if='isExistingFeature' color="primary"
              @click="updateAdFeature">
                Save
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-divider v-if="isExistingFeature"/>
      <v-tabs v-if="isExistingFeature" background-color="white" hide-slider height="35" v-model="tab">
        <v-tab :ripple="false" class="text-capitalize" v-for="(component, index) in tabs" :key="index" :disabled='!component.show'>
          <span v-if="component.show">
            <v-icon>{{component.icon}}</v-icon>
            {{component.name}}
          </span>
        </v-tab>
        <v-tab-item v-for="component in tabs" :key="component.name">
          <component
            :is="component.content"
            :tab="tab"
            :showInfo="showInfo"
            :selectedAd="selectedAd"
            :feature="feature"
            :readonly="readonly"
            :adParty="adParty" />
        </v-tab-item>
      </v-tabs>
      <v-divider v-if="isExistingFeature"/>
      <v-card-actions>
        <v-spacer/>
        <v-btn :class="body" text @click="$emit('update:value', false)">
          {{isExistingFeature ? 'Close' : 'Cancel'}}
        </v-btn>
        <v-btn v-if="!isExistingFeature" :disabled="readonly" :class="body" class="green white--text" @click="isExistingFeature ? updateAdFeature() : createAdFeature()">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog :value.sync="modal" persistent width="500">
      <v-card>
        <v-card-title class="font-weight-light">
          <v-icon class="red--text" large left>mdi-alert-octagon-outline</v-icon>
          Are you sure you want to close feature?
        </v-card-title>
        <v-card-text class="pa-6">
          <v-row justify="center">
          You have unsaved changes that will be lost.
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text :class="body" @click="modal = false">Cancel</v-btn>
          <v-btn :class="body" class="primary white--text ml-2" @click="$emit('update:value', false)">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { displayHelpers } from '@/mixins/display-helpers'
import { format } from '@/mixins/format'
import { tableConfig } from '@/mixins/table-config'

export default {
  name: 'FeatureView',

  components: {
    FeaturePromoItems: () => import('@/components/features/feature-promo-items'),
    FeatureNonPromoItems: () => import('@/components/features/feature-nonpromo-items'),
    FeatureItemSearch: () => import('@/components/features/feature-item-search'),
    PromotionList: () => import('@/components/features/promotion-list')
  },

  mixins: [displayHelpers, format, tableConfig],

  props: [
    'currentPage',
    'getAdPageFeatures',
    'adFeatures',
    'selectedFeature',
    'selectedAd',
    'readonly',
    'value',
    'adParty'
  ],

  data: () => ({
    localPage : {},
    tab: 0,
    showInfo: true,
    pagePositions: [],
    posLoad: false,
    menu: false,
    feature: {},
    modal: false,
  }),

  computed: {
    breadcrumbs () {
      return [
        {
          text: this.classification + "  " + this.selectedAd.ad_name,
          exact: true,
          disabled: false
        },
        {
          text: this.selectedAd.ad_start_date + ' : ' + this.selectedAd.ad_end_date,
          exact: true,
          disabled: true
        },
      ]
    },
    validFeature () {
      return !this._.isEmpty(this.feature.position) && !this._.isEmpty(this.feature.feature_text)
    },
    isExistingFeature () {
      return !this._.isEmpty(this.feature.id)
    },
    featureTextRules () {
      return this.$auth.tenant === 'pricechopper' ? [v => !!v || 'Feature Text Required'] : [v => !!v || 'Feature Text Required', this.validateText]
    },
    featureNotesRules () {
      return this.$auth.tenant === 'pricechopper' ? [] : [this.validateText]
    },
    featureUpdated () {
      return !this._.isEqual(this.selectedFeature, this.feature)
        || (this.selectedFeature.promo_item_group_ids.length !== this.feature.promo_item_group_ids.length
        && this.selectedFeature.non_promo_items.length !== this.feature.non_promo_items.length)
    },
    tabs () {
      if (!this.readonly) {
        return [
          { name: 'Promotional', content: 'FeaturePromoItems', icon: 'mdi-currency-usd', show: true },
          { name: 'Non-Promotional', content: 'FeatureNonPromoItems', icon: 'mdi-currency-usd-off', show: true },
          { name: 'Promotion List', content: 'PromotionList', icon: 'mdi-note-text', show: true},
          { name: 'Item Search', content: 'FeatureItemSearch', icon: 'mdi-magnify', show: true }
        ]
      } else {
        return [
          { name: 'Promotional', content: 'FeaturePromoItems', icon: 'mdi-currency-usd', show: true },
          { name: 'Non-Promotional', content: 'FeatureNonPromoItems', icon: 'mdi-currency-usd-off', show: true },
        ]
      }
    },
    featPos () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl': return this.isExistingFeature ? '2' : '3';
        case 'lg': return this.isExistingFeature ? '2' : '3';
        case 'md': return this.isExistingFeature ? '2' : '3';
        default: return '5em';
      }
    },
    featPage () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl': return this.isExistingFeature ? '2' : '3';
        case 'lg': return this.isExistingFeature ? '2' : '3';
        case 'md': return this.isExistingFeature ? '2' : '3';
        default: return '5em';
      }
    },
    featText () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl': return this.isExistingFeature ? '5' : '8';
        case 'lg': return this.isExistingFeature ? '5' : '8';
        case 'md': return this.isExistingFeature ? '5' : '8';
        default: return '5em';
      }
    },
    featNotes () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl': return this.isExistingFeature ? '5' : '8';
        case 'lg': return this.isExistingFeature ? '5' : '8';
        case 'md': return this.isExistingFeature ? '4' : '8';
        default: return '5em';
      }
    },
    existingFeatures () {
      return this.adFeatures.reduce((acc, result) => {
        result = {
          id: result.position.id,
          feature_id: result.id
        }
        acc.push(result)
        return acc
      }, [])
    },
    classification () {
      return this.$store.getters.classifications.find(c => c.id === this.selectedAd.classification).constant
    },
    pages () {
      return this.$store.getters.adPages
    }
  },

  watch: {
    value: {
      immediate: true,
      handler () {
        if (this.value) {
          this.localPage = this.currentPage
          this.getAdPages(this.feature.page)
        }
      }
    },
    selectedFeature: {
      immediate: true,
      handler () {
        if (!this._.isEmpty(this.selectedFeature)) {
          this.feature = this._.cloneDeep(this.selectedFeature)
        } else {
          this.showInfo = true
          this.feature = {
            ad_id: '',
            feature_text: '',
            promo_item_group_ids: [],
            notes: '',
            non_promo_items: [],
            page: this.localPage,
            position: {},
            count: 1,
            ad_retail:1.00,
            family:'',
            brand_combo:'',
            sign_detail: ''
          }
        }
      }
    },
    localPage: {
      immediate: false,
      handler(){
        if (this.isExistingFeature){
          this.feature.page = this.localPage
          this.updateAdFeature()
          this.getPagePositions()
        }
        else{
          this.feature.page = this.localPage
        }
      }
    }
  },
  methods: {
    getAdPages () {
      this.getPagePositions(this.feature.page) 
      this.getAdPageFeatures(this.localPage)
    },
    async getPagePositions () {
      let page = `PAGE_POSITION_${this.localPage.constant}`
      const params={page:page, limit:500}
      await this.$Features.getFeaturePositions(this.selectedAd.id, params)
        .then(res => {
          this.pagePositions = res.data
          if(this.isExistingFeature){
            this.pagePositions.push(this.feature.position)
          }
        }).catch(err=>{
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Page Positions due to ${err?.response?.data?.message || err.message}` })
        }).finally()
    },
    async getFeature (featureId) {
      this.$Features.getAdFeatureById(featureId)
        .then(res => {
          this.feature = res.data
          this.feature.ad_retail = parseFloat(this.feature.ad_retail).toFixed(2)
        }).catch(err=>{
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Feature due to ${err?.response?.data?.message || err.message}` })
        })
    },
    validate_count(count){
        let numbers_only = /^[0-9]+$/;
        count = count.toString()
        if(!count.match(numbers_only)){
          return false;
        }
        return true
    },
    validate_ad_retail(ad_retail){
      let numbers_and_decimal = /^\d{1,5}(\.\d{1,2})?$/;
      ad_retail = ad_retail.toString()
      if(!ad_retail.match(numbers_and_decimal)){
          return false;
        }
        return true
    },
    validateText (value) {
      const allowedChars = ['$', '/', '.', '-', ' ', `'`, `,`]
      if (value) {
        const chars = [...value]
        const invalidChars = chars.filter(c => {
          return (/[\W_]+/g.test(c)) && !allowedChars.includes(c)
        })
        return (invalidChars.length === 0) || `Invalid characters: ${[...new Set(invalidChars)].join(' ')}`
      }
      return true
    },
    async createAdFeature () {
      const valid = this.$refs.form.validate()
      if (valid) {
        const newFeature = {
          ad_id: this.selectedAd.id,
          feature_text: this.feature.feature_text,
          promo_item_group_ids: [],
          notes: this.feature.notes,
          non_promo_items: [],
          page: this.feature.page,
          position: this.feature.position,
          count: 1,
          ad_retail: 1.00,
          family: this.feature.family,
          brand_combo: this.feature.family,
          sign_detail: this.feature.sign_detail
        }
        newFeature.ad_retail = parseFloat(newFeature.ad_retail).toFixed(2)
        try {
          const res = await this.$Features.createAdFeature(newFeature)
          if (res?.data?.id) {
            let featureId = res.data.id
            this.$emit('refresh')
            this.$store.dispatch('setSnackbar', { status: 'success', text: 'Feature Created' })
            this.getFeature(featureId)
            this.getAdPageFeatures(this.localPage)
          }
        } catch (err) {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Create Feature due to ${err?.response?.data?.message || err.message}` })
        }
      }
    },
    async updateAdFeature () {
      const valid = this.$refs.form.validate()
      if (valid) {
        try {
          await this.$Features.updateAdFeature(this.feature.id, this.feature)
          this.$store.dispatch('setSnackbar', { status: 'success', text: 'Feature Updated' })
          this.getAdPageFeatures(this.feature.page)
        } catch (err) {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Update Feature due to ${err?.response?.data?.message || err.message}` })
        }
      }
    },
    async deleteAdFeature () {
      await this.$Features.deleteAdFeature(this.selectedFeature.id)
        .then(() => {
          this.$emit('update:value', false)
          this.$store.dispatch('setSnackbar', { status: 'success', text: `${this.feature.position.name} Deleted` })
        }).catch(err => {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Delete Feature due to ${err?.response?.data?.message || err.message}` })
        })
      this.getAdPageFeatures()
    },
  }
}
</script>
<style scoped>
  .active {
    box-shadow: 0px 2px 10px 3px rgba(120,120,120,0.75);
    background: #385F73;
    color: white !important;
  }
</style>